import { atom } from "recoil";

export const PwSettingModalStatus = atom({
  key: "PwSettingModalStatus",
  default: {
    open: false,
  },
});

export const UserSettingModalStatus = atom({
  key: "UserSettingModalStatus",
  default: {
    open: false,
  },
});

export const DataConsolidationModalStatus = atom({
  key: "DataConsolidationModalStatus",
  default: {
    open: false,
    target_id: null,
    sales_id: null,
    table_name: null,
    prev_table_name: null,
  },
});

export const PackingListDataConsolidationModalStatus = atom({
  key: "PackingListDataConsolidationModalStatus",
  default: {
    open: false,
    target_id: null,
    sales_id: null,
    table_name: null,
    prev_table_name: null,
  },
});

export const ReleaseDataConsolidationModalStatus = atom({
  key: "ReleaseDataConsolidationModalStatus",
  default: {
    open: false,
    target_id: null,
    sales_id: null,
    table_name: null,
  },
});

export const RequestDataConsolidationModalStatus = atom({
  key: "RequestDataConsolidationModalStatus",
  default: {
    open: false,
    target_id: null,
    sales_id: null,
    table_name: null,
  },
});

export const QtyControlDataConsolidationModalStatus = atom({
  key: "QtyControlDataConsolidationModalStatus",
  default: {
    open: false,
    target_id: null,
    inv_name: null,
  },
});

export const UserAddMenuModalStatus = atom({
  key: "UserAddMenuModalStatus",
  default: {
    open: false,
    target_id: null,
    user_id: null,
    username: null,
  },
});

export const MenuAddUserModalStatus = atom({
  key: "MenuAddUserModalStatus",
  default: {
    open: false,
    target_id: null,
    menu_url: null,
    menu_name: null,
  },
});

export const ProjectAddUserModalStatus = atom({
  key: "ProjectAddUserModalStatus",
  default: {
    open: false,
    sales_id: null,
    sales_lead: null,
  },
});

export const MaterialModalStatus = atom({
  key: "MaterialModalStatus",
  default: {
    open: false,
    table_name: null,
    parent_id: null,
    query_key: null,
  },
});

export const ContactPointStatus = atom({
  key: "ContactPointStatus",
  default: {
    open: false,
    table_name: null,
    sales_id: null,
    customer_id: null,
  },
});

export const JobNumberStatus = atom({
  key: "JobNumberStatus",
  default: {
    open: false,
    sales_id: null,
    query_key: null,
  },
});
