import React, { useMemo, useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { UserGeneralMenu } from "@/atoms/userInfo";
import { useTreeViewApiRef, RichTreeView } from "@mui/x-tree-view";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import KeyboardDoubleArrowLeftRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import { unstable_useTreeItem2 as useTreeItem2 } from "@mui/x-tree-view/useTreeItem2";
import {
  TreeItem2Checkbox,
  TreeItem2IconContainer,
  TreeItem2Label,
} from "@mui/x-tree-view/TreeItem2";
import { TreeItem2Icon } from "@mui/x-tree-view/TreeItem2Icon";
import { TreeItem2Provider } from "@mui/x-tree-view/TreeItem2Provider";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import FolderRounded from "@mui/icons-material/FolderRounded";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import Collapse from "@mui/material/Collapse";
import clsx from "clsx";
import {
  StyledTreeItemRoot,
  CustomTreeItemContent,
  StyledTreeItemLabelText,
} from "@styles/customStyled";

const Sidebar = ({ top_menu, menu }) => {
  // eslint-disable-next-line
  const navigate = useNavigate();
  const { userGeneralMenu, adminMenu } = useRecoilValue(UserGeneralMenu);
  const apiRef = useTreeViewApiRef();
  const [sidebarWidth, setSidebarWidth] = useState(expandedWidth);

  const handleToggleNav = () => {
    setSidebarWidth(
      sidebarWidth === expandedWidth ? collapsedWidth : expandedWidth
    );
  };

  const [expandedItems, setExpandedItems] = useState([]);

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  const handleExpandClick = () => {
    setExpandedItems((oldExpanded) =>
      oldExpanded.length === 0
        ? getAllItemsWithChildrenItemIds(userGeneralMenu)
        : []
    );
  };

  console.log(useRecoilValue(UserGeneralMenu));

  const selectMenu = useMemo(() => {
    if (top_menu === "home") {
      return userGeneralMenu ?? [];
    }

    if (top_menu === "admin") {
      return adminMenu ?? [];
    }

    return [];
  }, [top_menu, userGeneralMenu, adminMenu]);

  return (
    <div
      className={`sidebar-total-body ${
        sidebarWidth !== expandedWidth ? "none" : "active"
      }`}
    >
      <Box
        sx={{
          height: `calc(100vh - ${headerHeight} - 100px)`,
          overflowY: "auto",
        }}
      >
        {sidebarWidth === expandedWidth && (
          <Stack
            spacing={4}
            sx={{
              flex: 1,
              minHeight: "0",
              padding: 2,
              bgcolor: "background.paper",
            }}
          >
            <RichTreeView
              items={selectMenu}
              apiRef={apiRef}
              slots={{ item: CustomTreeItem }}
              expandedItems={expandedItems}
              onExpandedItemsChange={handleExpandedItemsChange}
              onItemSelectionToggle={(event, itemIds, isSelected) => {
                const item = apiRef.current.getItem(itemIds);

                if (item?.path) {
                  navigate(item.path);
                }
              }}
              multiSelect={false} // Set to true if you need multi-selection
              checkboxSelection={false} // Enable if you want checkboxes
              sx={{
                "& .MuiTypography-root": {
                  paddingInline: "10px",
                },
              }}
            />
          </Stack>
        )}
      </Box>
      <div className="siderBar-bottom-group">
        <div
          className={`siderBar-copy-right-group ${
            sidebarWidth !== expandedWidth ? "none" : "active"
          }`}
        >
          Copyright © 2024 Plant Module. <br />
          All rights reserved by Plant Module
        </div>
        <div
          className={`siderBar-control-btn-group ${
            sidebarWidth !== expandedWidth ? "none" : "active"
          }`}
        >
          <Button
            onClick={handleToggleNav}
            className="siderbar-menu-control-btn"
            startIcon={
              sidebarWidth === expandedWidth ? (
                <KeyboardDoubleArrowLeftRoundedIcon
                  style={{ color: "lightgray" }}
                /> // Any hex color
              ) : (
                <KeyboardDoubleArrowRightRoundedIcon
                  style={{ color: "lightgray" }}
                />
              ) // Any hex color
            }
          />

          <Button
            onClick={handleExpandClick}
            className={`siderbar-menu-list-control-btn ${
              sidebarWidth !== expandedWidth ? "none" : "active"
            }`}
            startIcon={
              expandedItems.length === 0 ? (
                <AddCircleOutlineRoundedIcon style={{ color: "lightgray" }} /> // Any hex color
              ) : (
                <RemoveCircleOutlineRoundedIcon
                  style={{ color: "lightgray" }}
                />
              ) // Any hex color
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

const AnimatedCollapse = animated(Collapse);

function TransitionComponent(props) {
  const { in: props_in } = props;
  const style = useSpring({
    to: {
      opacity: props_in ? 1 : 0,
      transform: `translate3d(0,${props_in ? 0 : 20}px,0)`,
    },
  });

  return <AnimatedCollapse style={style} {...props} />;
}

function CustomLabel({ icon: Icon, expandable, children, ...other }) {
  return (
    <TreeItem2Label
      {...other}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {Icon && (
        <Box
          component={Icon}
          className="labelIcon"
          color="inherit"
          sx={{ mr: 1, fontSize: "1.2rem" }}
        />
      )}

      <StyledTreeItemLabelText variant="body2">
        {children}
      </StyledTreeItemLabelText>
      {expandable && <DotIcon />}
    </TreeItem2Label>
  );
}
const expandedWidth = "16rem";
const collapsedWidth = "64px";
const headerHeight = "75px";

const getAllItemsWithChildrenItemIds = (MenuList) => {
  const itemIds = [];
  const registerItemId = (item) => {
    if (item.children?.length) {
      itemIds.push(item.id);
      item.children.forEach(registerItemId);
    }
  };

  MenuList.forEach(registerItemId);

  return itemIds;
};

const isExpandable = (reactChildren) => {
  if (Array.isArray(reactChildren)) {
    return reactChildren.length > 0 && reactChildren.some(isExpandable);
  }
  return Boolean(reactChildren);
};

const getIconFromFileType = (fileType) => {
  switch (fileType) {
    case "image":
      return ImageIcon;
    case "pdf":
      return PictureAsPdfIcon;
    case "doc":
      return ArticleIcon;
    case "video":
      return VideoCameraBackIcon;
    case "folder":
      return FolderRounded;
    case "pinned":
      return FolderOpenIcon;
    case "trash":
      return DeleteIcon;
    default:
      return ArticleIcon;
  }
};

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  const { id, itemId, label, disabled, children, ...other } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const item = publicAPI.getItem(itemId);
  const expandable = isExpandable(children);
  let icon;
  if (expandable) {
    icon = FolderRounded;
  } else if (item.fileType) {
    icon = getIconFromFileType(item.fileType);
  }

  return (
    <TreeItem2Provider itemId={itemId}>
      <StyledTreeItemRoot {...getRootProps(other)}>
        <CustomTreeItemContent
          {...getContentProps({
            className: clsx("content", {
              "Mui-expanded": status.expanded,
              "Mui-selected": status.selected,
              "Mui-focused": status.focused,
              "Mui-disabled": status.disabled,
            }),
          })}
        >
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <TreeItem2Checkbox {...getCheckboxProps()} />
          <CustomLabel
            {...getLabelProps({
              icon,
              expandable: expandable && status.expanded,
            })}
          />
        </CustomTreeItemContent>
        {children && <TransitionComponent {...getGroupTransitionProps()} />}
      </StyledTreeItemRoot>
    </TreeItem2Provider>
  );
});

function DotIcon() {
  return (
    <Box
      sx={{
        width: 6,
        height: 6,
        borderRadius: "70%",
        bgcolor: "warning.main",
        display: "inline-block",
        verticalAlign: "middle",
        zIndex: 1,
        mx: 1,
      }}
    />
  );
}

// const MenuList = [
//   {
//     id: "Sales",
//     label: "Sales",
//     fileType: "folder",
//     children: [
//       {
//         id: "pipeline",
//         label: "Sales Pipeline Management",
//         path: "/home/sales",
//         fileType: "",
//       },
//     ],
//   },
//   {
//     id: "Company",
//     label: "Company",
//     fileType: "folder",
//     children: [
//       {
//         id: "mm",
//         label: "Material Master",
//         path: "/home/material_master",
//         fileType: "",
//       },
//       {
//         id: "ve",
//         label: "Vendor List",
//         path: "/home/vendor",
//         fileType: "",
//       },
//     ],
//   },
//   {
//     id: "Material",
//     label: "Material",
//     fileType: "folder",
//     children: [
//       {
//         id: "pr",
//         label: "Purchase Requisition",
//         path: "/home/purchase_request",
//         fileType: "",
//       },

//       {
//         id: "po",
//         label: "Purchase Order",
//         path: "/home/purchase_order",
//         fileType: "",
//       },
//       {
//         id: "pl",
//         label: "Packing List",
//         path: "/home/packing_list",
//         fileType: "",
//       },
//       {
//         id: "in",
//         label: "Material Received",
//         path: "/home/material_receive",
//         fileType: "",
//       },
//       {
//         id: "out",
//         label: "Material Release",
//         path: "/home/material_release",
//         fileType: "",
//       },
//       {
//         id: "result",
//         label: "Material Inventory",
//         path: "/home/material_inventory",
//         fileType: "",
//       },
//     ],
//   },
// ];
