import React, { useEffect, useMemo, useState, useRef } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { PackingListDataConsolidationModalStatus } from "@atoms/modalStatus";
import { Modal, IconButton, Button, Tabs, Tab, Box } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import axios from "axios";
import SelectDataTable from "@components/SelectDataTable";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";
import {
  createPOTableColumn,
  createPOLineTableColumn,
  createPRTableColumn,
} from "./Columns";

import "./packingListDataConsolidationModal.scss";

const PackingListDataConsolidationModal = () => {
  const { width, height } = useWindowDimensions();

  const [modalStatus, setModalStatus] = useRecoilState(
    PackingListDataConsolidationModalStatus
  );

  const { open, target_id, sales_id, table_name, prev_table_name } =
    modalStatus;

  const [dubleClick, setDubleClick] = useState(true);
  const [upperSelectionModel, setUpperSelectionModel] = useState([]);
  const [lineSelectionModel, setLineSelectionModel] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [materialList, setMaterialList] = useState([]);
  const [tabMenuValue, setTabMenuValue] = useState(0);

  const cancelTokenSource = useRef(null);

  const query_pr_list = useQuery(
    [
      "data_consolidation_qr_list",
      sales_id,
      prev_table_name,
      table_name,
      target_id,
    ],
    () => {
      return query_qr_fetch_data(
        sales_id,
        prev_table_name,
        table_name,
        target_id
      );
    },
    {
      initialData: { totalUpperList: [] },
    }
  );

  const query_pr_line_list = useQuery(
    [
      "data_consolidation_qr_line_list",
      target_id,
      sales_id,
      upperSelectionModel,
      prev_table_name,
    ],
    () => {
      return query_qr_line_fetch_data(
        target_id,
        sales_id,
        upperSelectionModel,
        prev_table_name
      );
    },
    {
      initialData: { totalLineList: [] },
    }
  );

  const { totalUpperList } = query_pr_list.data;
  const { totalLineList } = query_pr_line_list.data;

  useEffect(() => {
    setUpperSelectionModel([]);
    setLineSelectionModel([]);
    setSearchText("");
  }, [open, tabMenuValue]);

  const closeModal = () => {
    setModalStatus((prev) => ({
      ...prev,
      open: false,
      target_id: null,
      sales_id: null,
      table_name: null,
      prev_table_name: null,
    }));
  };

  const button_disabled = useMemo(() => {
    if (tabMenuValue === 0) {
      if (!target_id || !sales_id || lineSelectionModel.length === 0) {
        return true;
      }

      return false;
    }

    if (tabMenuValue === 1) {
      if (!target_id || !sales_id || upperSelectionModel.length === 0) {
        return true;
      }

      return false;
    }

    return true;
  }, [
    lineSelectionModel,
    target_id,
    sales_id,
    tabMenuValue,
    upperSelectionModel,
  ]);

  const table_columns = useMemo(() => {
    let result = { upper: [], line: [] };

    if (tabMenuValue === 0) {
      result.upper = createPOTableColumn();
      result.line = createPOLineTableColumn();
    }

    if (tabMenuValue === 1) {
      result.upper = createPRTableColumn();
      result.line = [];
    }

    return result;
  }, [tabMenuValue]);

  const createDataConsolidationListItem = async () => {
    try {
      let dataResult;

      if (tabMenuValue === 0) {
        const requestDataConsolidation = await userPermAxios.post(
          `/${table_name}/${sales_id}/${target_id}/consolidation-lineitem`,
          {
            lineData: lineSelectionModel.map((c) => {
              const findLineData = totalLineList.find((c2) => c2.id === c);

              return findLineData;
            }),
          }
        );

        dataResult = requestDataConsolidation;
      }

      if (tabMenuValue === 1) {
        const requestDataConsolidation = await userPermAxios.post(
          `/${table_name}/${sales_id}/${target_id}/consolidation-lineitem`,
          {
            lineData: upperSelectionModel.map((c) => {
              const findLineData = materialList.find((c2) => c2.id === c);

              return findLineData;
            }),
            linkNot: true,
          }
        );

        dataResult = requestDataConsolidation;
      }

      const { success } = dataResult;

      if (success) {
        closeModal();
      }
    } catch (err) {
      create_alert_fn("warning", "Data Consolidation Error", () => {});
    } finally {
      setDubleClick(true);
    }
  };

  const openSubmitModal = () => {
    if (!dubleClick) return;

    setDubleClick(false);

    create_alert_fn(
      "info",
      "Are you sure you want to create a line item?",
      async () => {
        await createDataConsolidationListItem();
      }
    );
  };

  const modalHeight = height * 0.9;

  const handleChange = (event, newValue) => {
    setTabMenuValue(newValue);
  };

  const contentsHeight = modalHeight - 70 - 76 - 32;

  const searchTargetMaterialList = async (e) => {
    const value = e.target.value;

    setSearchText(value);
    setUpperSelectionModel([]);

    const valueReplace = value.replaceAll(" ", "");

    if (valueReplace.length > 0) {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel(
          "Operation canceled due to new request."
        );
      }

      // 새로운 cancel token 생성
      cancelTokenSource.current = axios.CancelToken.source();

      try {
        const requestMaterialList = await userPermAxios.post(
          `/${table_name}/${target_id}/${sales_id}/search_material_list`,
          {
            searchText: value,
          },
          {
            cancelToken: cancelTokenSource.current.token,
          }
        );

        const { success, data } = requestMaterialList;

        if (success) {
          setMaterialList(data);
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          console.error("Request failed:", err);
        }
      }
    } else {
      setMaterialList([]);
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="data-consolidation-modal-total-body"
        style={{ width: width * 0.9, height: modalHeight }}
      >
        <div className="data-consolidation-modal-header-box">
          <div className="data-consolidation-modal-header-title">
            Data Consolidation
          </div>
          <IconButton
            onClick={closeModal}
            className="data-consolidation-modal-close-btn"
          >
            <CloseOutlined fontSize="small" />
          </IconButton>
        </div>

        <div
          className="data-consolidation-modal-main-box"
          style={{ height: contentsHeight }}
        >
          <Box>
            <Box>
              <Tabs
                value={tabMenuValue}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Supplier Provided"
                  {...a11yProps(0)}
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  label="Customer Supplied"
                  {...a11yProps(1)}
                  sx={{ textTransform: "none" }}
                />
              </Tabs>
            </Box>
          </Box>
          <CustomTabPanel value={tabMenuValue} index={0}>
            <SelectDataTable
              title={upper_table_title(table_name)}
              rows={totalUpperList}
              columns={table_columns.upper}
              selectModel={upperSelectionModel}
              setSelectModel={setUpperSelectionModel}
              table_height={(contentsHeight - 48 - 32 * 3) / 2}
            />

            <SelectDataTable
              title="Line Item List"
              rows={totalLineList}
              columns={table_columns.line}
              selectModel={lineSelectionModel}
              setSelectModel={setLineSelectionModel}
              table_height={(contentsHeight - 48 - 32 * 3) / 2}
              isRowSelectableField="material_use"
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabMenuValue} index={1}>
            <SelectDataTable
              title="Material List"
              rows={materialList}
              columns={table_columns.upper}
              selectModel={upperSelectionModel}
              setSelectModel={setUpperSelectionModel}
              table_height={contentsHeight - 48 - 32 * 2}
              isRowSelectableField="material_use"
              searchUse={true}
              searchValue={searchText}
              searchOnchange={searchTargetMaterialList}
              searchPlaceholder="Search Material..."
              searchLeftIcon={<SearchOutlinedIcon fontSize="small" />}
              searchRightIcon={
                <IconButton onClick={() => setSearchText("")}>
                  <CloseOutlinedIcon fontSize="small" />
                </IconButton>
              }
            />
          </CustomTabPanel>
        </div>

        <div className="data-consolidation-modal-btn-box">
          <Button
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            onClick={closeModal}
            className="data-consolidation-modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            onClick={openSubmitModal}
            className="data-consolidation-modal-update-btn"
            disabled={button_disabled}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const query_qr_fetch_data = async (
  sales_id,
  table_name,
  target_table_name,
  target_id
) => {
  const result = { totalUpperList: [] };

  try {
    if (!sales_id) {
      return result;
    }

    const request_pr_list = await userPermAxios.get(
      `/${table_name}/${sales_id}/${target_table_name}/${target_id}/get_data_consolidation_upper_list`
    );

    const { success, data } = request_pr_list;

    if (success) {
      result.totalUpperList = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const query_qr_line_fetch_data = async (
  target_id,
  sales_id,
  upperSelectionModel = [],
  table_name
) => {
  const result = { totalLineList: [] };

  try {
    if (upperSelectionModel.length === 0) {
      return result;
    }

    const request_pr_list = await userPermAxios.post(
      `/${table_name}/${target_id}/${sales_id}/get_data_consolidation_lineitem_list`,
      {
        ids: upperSelectionModel,
      }
    );

    const { success, data } = request_pr_list;

    if (success) {
      result.totalLineList = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const upper_table_title = (table_name) => {
  if (table_name === "request_for_quotation") {
    return "Purchase Requisition List";
  }
  if (table_name === "purchase_order") {
    return "Request for Quotation List";
  }
  if (table_name === "packing_list") {
    return "Purchase Order List";
  }
  if (table_name === "material_receive") {
    return "Packing List List";
  }
  if (table_name === "Product Export") {
    return "Material Release List";
  }

  return "";
};

export default PackingListDataConsolidationModal;

function a11yProps(index) {
  return {
    id: `update-comment-tab-${index}`,
    "aria-controls": `update-comment-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  const styles =
    value === 0
      ? { display: "flex", flexDirection: "column", gap: "32px" }
      : {};

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`update-comment-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        width: "100%",
        ...styles,
      }}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
