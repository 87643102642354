import React, { useEffect, useState, useMemo, useRef } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import {
  RequestDataConsolidationModalStatus,
  MaterialModalStatus,
} from "@atoms/modalStatus";
import axios from "axios";
import { Modal, IconButton, Button } from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SelectDataTable from "@components/SelectDataTable";
import MaterialAddModal from "@components/MaterialAddModal/MaterialAddModal";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { userPermAxios } from "@utils/customAxios";
import { create_alert_fn } from "@utils/createAlert";
import { createPRTableColumn } from "./Column";
import "./requestDataConsolidationModal.scss";

const RequestDataConsolidationModal = () => {
  const { width, height } = useWindowDimensions();

  const [modalStatus, setModalStatus] = useRecoilState(
    RequestDataConsolidationModalStatus
  );

  const { open, target_id, sales_id } = modalStatus;

  const [dubleClick, setDubleClick] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [materialList, setMaterialList] = useState([]);
  const [materialAddModalStatus, setMaterialAddModalStatus] =
    useRecoilState(MaterialModalStatus);
  const cancelTokenSource = useRef(null);

  // const query_material_list = useQuery(
  //   ["material_list", target_id, sales_id],
  //   () => {
  //     return query_fetch_data(target_id, sales_id);
  //   },
  //   {
  //     initialData: { material_list: [] },
  //   }
  // );

  // const { material_list } = query_material_list.data;

  useEffect(() => {
    setSelectionModel([]);
  }, [open]);

  const closeModal = () => {
    setModalStatus((prev) => ({
      ...prev,
      open: false,
      target_id: null,
      sales_id: null,
    }));
  };

  const button_disabled = useMemo(() => {
    if (!target_id || !sales_id || selectionModel.length === 0) {
      return true;
    }

    return false;
  }, [selectionModel, target_id, sales_id]);

  const createReleaseDataConsolidationListItem = async () => {
    try {
      const requestDataConsolidation = await userPermAxios.post(
        `/purchase_request/${sales_id}/${target_id}/consolidation-lineitem`,
        {
          lineData: selectionModel.map((c) => {
            const findLineData = materialList.find((c2) => c2.id === c);

            return findLineData;
          }),
        }
      );

      const { success } = requestDataConsolidation;

      if (success) {
        closeModal();
      }
    } catch (err) {
      create_alert_fn("warning", "Data Consolidation Error", () => {});
    } finally {
      setDubleClick(true);
    }
  };

  const openSubmitModal = () => {
    if (!dubleClick) return;
    setDubleClick(true);

    create_alert_fn(
      "info",
      "Are you sure you want to create a line item?",
      async () => {
        await createReleaseDataConsolidationListItem();
      }
    );
  };

  const openMaterialAddModal = () => {
    setMaterialAddModalStatus((prev) => {
      return {
        ...prev,
        open: true,
        table_name: "purchase_request",
        parent_id: target_id,
        query_key: ["material_list", target_id, sales_id],
      };
    });
  };

  const modalHeight = height * 0.9;

  const searchTargetMaterialList = async (e) => {
    const value = e.target.value;

    setSearchText(value);
    setSelectionModel([]);

    const valueReplace = value.replaceAll(" ", "");

    if (valueReplace.length > 0) {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel(
          "Operation canceled due to new request."
        );
      }

      // 새로운 cancel token 생성
      cancelTokenSource.current = axios.CancelToken.source();

      try {
        const requestMaterialList = await userPermAxios.post(
          `/purchase_request/${target_id}/${sales_id}/search_material_list`,
          {
            searchText: value,
          },
          {
            cancelToken: cancelTokenSource.current.token,
          }
        );

        const { success, data } = requestMaterialList;

        if (success) {
          setMaterialList(data);
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log("Request canceled:", err.message);
        } else {
          console.error("Request failed:", err);
        }
      }
    } else {
      setMaterialList([]);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="request-data-consolidation-modal-total-body"
          style={{ width: width * 0.9, height: modalHeight }}
        >
          <div className="request-data-consolidation-modal-header-box">
            <div className="request-data-consolidation-modal-header-title">
              Data Consolidation
            </div>
            <IconButton
              onClick={closeModal}
              className="request-data-consolidation-modal-close-btn"
            >
              <CloseOutlined fontSize="small" />
            </IconButton>
          </div>

          <div
            className="request-data-consolidation-modal-main-box"
            style={{ height: modalHeight - 70 - 76 - 32 }}
          >
            <SelectDataTable
              title="Material List"
              rows={materialList}
              columns={createPRTableColumn()}
              selectModel={selectionModel}
              setSelectModel={setSelectionModel}
              table_height={modalHeight - 70 - 76 - 32 - 40}
              isRowSelectableField="material_use"
              searchUse={true}
              searchValue={searchText}
              searchOnchange={searchTargetMaterialList}
              searchPlaceholder="Search Material..."
              searchLeftIcon={<SearchOutlinedIcon fontSize="small" />}
              searchRightIcon={
                <IconButton onClick={() => setSearchText("")}>
                  <CloseOutlinedIcon fontSize="small" />
                </IconButton>
              }
            />
          </div>

          <div className="request-data-consolidation-modal-btn-box">
            <Button
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              onClick={openMaterialAddModal}
              className="request-data-consolidation-modal-cancel-btn"
            >
              Add New Material
            </Button>
            <div className="request-data-consolidation-modal-btn-box2">
              <Button
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                onClick={closeModal}
                className="request-data-consolidation-modal-cancel-btn"
              >
                Cancel
              </Button>
              <Button
                onClick={openSubmitModal}
                className="request-data-consolidation-modal-update-btn"
                disabled={button_disabled}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {materialAddModalStatus?.open && <MaterialAddModal />}
    </>
  );
};

export default RequestDataConsolidationModal;

const query_fetch_data = async (target_id, sales_id) => {
  const result = { material_list: [] };

  try {
    if (!sales_id) {
      return result;
    }

    const request_material_list = await userPermAxios.get(
      `/${target_id}/${sales_id}/get_request_material_list`
    );

    const { success, data } = request_material_list ?? {};

    if (success) {
      result.material_list = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};
