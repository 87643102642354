import React from "react";
import { InputLabel, Select, MenuItem } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { labelSelectTheme } from "@styles/muiThemes";
import "@styles/components.scss";

const LabelSelect = ({
  label,
  value,
  setValue,
  list = [],
  disabled = false,
  helperText,
  padding,
}) => {
  return (
    <div className="label-select-box">
      <ThemeProvider theme={labelSelectTheme}>
        <InputLabel
          htmlFor={`attachment-modal-selector-${label}`}
          className="label-input-title"
        >
          {label}
        </InputLabel>
        <Select
          id={`attachment-modal-selector-${label}`}
          value={value}
          placeholder=""
          onChange={(e) => {
            setValue(e.target.value);
          }}
          sx={{
            width: "100%",
          }}
          disabled={disabled}
        >
          {list.map((c, i) => {
            return (
              <MenuItem key={i} value={c.value}>
                {c.name}
              </MenuItem>
            );
          })}
        </Select>
        {helperText && (
          <div className="label-select-helper-text">{helperText}</div>
        )}
      </ThemeProvider>
    </div>
  );
};

export default LabelSelect;
