import React, { useEffect, useState, createRef, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { UserGeneralMenu } from "@/atoms/userInfo";
import { useQuery } from "react-query";
import { IconButton } from "@mui/material";
import UserPermTable from "@components/userPermTable/UserPermTable";
import {
  createUserCreateColumn,
  // createPermMenuSummaryColumn,
  createPermMenuColumn,
  createPermMenuLineColumn,
  createPermProjectColumn,
  createPermProjectLineColumn,
} from "@components/userPermTable/Columns";
import UserCreateModal from "@components/userPermTable/UserCreateModal";
import { userPermAxios } from "@utils/customAxios";
import "./menu.scss";

const UserPermPage = () => {
  const { menu } = useParams();
  const { permissionMenu: permission_menu_list } =
    useRecoilValue(UserGeneralMenu);
  const [apiRef, setApiRef] = useState(null);
  const [soketData, setSoketData] = useState(null);
  const [userCreateModal, setUserCreateModal] = useState(false);
  const webSocket = useRef(null);

  useEffect(() => {
    setApiRef(createRef());
  }, []);

  const table_info = useMemo(() => {
    if (menu === "user_registration") {
      return {
        table_name: "users",
        title: "User Register & Assign",
        columns: createUserCreateColumn("users"),
        lineDataUse: false,
        lineColumns: [],
      };
    }

    if (menu === "assign_user_to_bps") {
      return {
        table_name: "permission_menu",
        title: "Assign User to BPs",
        columns: createPermMenuColumn("permission_menu"),
        lineDataUse: true,
        lineColumns: createPermMenuLineColumn("permission_menu"),
      };
    }

    if (menu === "assign_user_to_project") {
      return {
        table_name: "permission_project",
        title: "Assign User to Project",
        columns: createPermProjectColumn("permission_project"),
        lineDataUse: true,
        lineColumns: createPermProjectLineColumn("permission_project"),
      };
    }

    return;
  }, [menu]);

  const query_amdin_user_table_data = useQuery(
    ["amdin_user_table_data", table_info],
    () => {
      return query_fetch_data(table_info?.table_name, permission_menu_list);
    },
    {
      initialData: { userList: [] },
    }
  );

  const { userList } = query_amdin_user_table_data.data;

  useEffect(() => {
    webSocket.current = new WebSocket(
      `${process.env.NODE_ENV === "development" ? "ws" : "wss"}://${
        process.env.REACT_APP_SOKET_URI
      }`
      // `wss://${process.env.REACT_APP_SOKET_URI}`
    );

    if (webSocket) {
      webSocket.current.onopen = () => {
        console.log("WebSocket 연결!");
      };

      webSocket.current.onclose = (error) => {};

      webSocket.current.onerror = (error) => {};

      webSocket.current.onmessage = (event) => {
        const { data } = event;

        const dataParse = JSON.parse(data);

        const { action, tablename } = dataParse;

        const { id, field, value } = dataParse.data;

        const api = apiRef?.current;

        if (table_info?.table_name === tablename) {
          setSoketData(dataParse);

          if (api) {
            if (action === "user-new-row") {
              api.updateRows([{ ...dataParse.data }]);
            }

            if (action === "update-cell") {
              api.updateRows([{ id: id, [field]: value }]);
            }
          }
        }
      };
      return () => {
        webSocket.current?.close();
      };
    }
    // eslint-disable-next-line
  }, [webSocket, apiRef, table_info]);

  return (
    <>
      <div className="admin-user-create-total-body">
        <div className="admin-user-create-title-box">
          <div className="admin-user-create-title">{table_info?.title}</div>
          {table_info?.table_name === "users" && (
            <IconButton
              className="admin-user-create-btn"
              onClick={() => setUserCreateModal((prev) => !prev)}
            >
              <UserCreateIcon />
            </IconButton>
          )}
        </div>
        <div className="admin-user-create-table-box">
          <UserPermTable
            rows={userList}
            columns={table_info?.columns ?? []}
            lineDataUse={table_info?.lineDataUse ?? false}
            lineColumns={table_info?.lineColumns ?? []}
            line_table_name={table_info?.table_name}
            table_name={table_info?.table_name}
            soketData={soketData}
            ref={apiRef}
          />
        </div>
      </div>
      <UserCreateModal open={userCreateModal} setOpen={setUserCreateModal} />
    </>
  );
};

export default UserPermPage;

const query_fetch_data = async (table_name, permission_menu_list) => {
  const result = { userList: [] };

  try {
    if (!table_name) {
      return;
    }

    if (table_name === "permission_menu") {
      result.userList = permission_menu_list;

      return;
    }

    const request_user_list = await userPermAxios.get(
      `/${table_name}/get-user-register`
    );

    const { success, data } = request_user_list;

    if (success) {
      result.userList = data;
    }
  } catch (err) {
  } finally {
    return result;
  }
};

const UserCreateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M19.25 24.5C22.1495 24.5 24.5 22.1495 24.5 19.25C24.5 16.3505 22.1495 14 19.25 14C16.3505 14 14 16.3505 14 19.25C14 22.1495 16.3505 24.5 19.25 24.5ZM20 17V18.5H21.5C21.9142 18.5 22.25 18.8358 22.25 19.25C22.25 19.6642 21.9142 20 21.5 20H20V21.5C20 21.9142 19.6642 22.25 19.25 22.25C18.8358 22.25 18.5 21.9142 18.5 21.5V20H17C16.5858 20 16.25 19.6642 16.25 19.25C16.25 18.8358 16.5858 18.5 17 18.5H18.5V17C18.5 16.5858 18.8358 16.25 19.25 16.25C19.6642 16.25 20 16.5858 20 17Z"
        fill="#344054"
      />
      <path
        d="M17 8C17 10.4853 14.9853 12.5 12.5 12.5C10.0147 12.5 8 10.4853 8 8C8 5.51472 10.0147 3.5 12.5 3.5C14.9853 3.5 17 5.51472 17 8ZM12.5 11C14.1569 11 15.5 9.65685 15.5 8C15.5 6.34315 14.1569 5 12.5 5C10.8431 5 9.5 6.34315 9.5 8C9.5 9.65685 10.8431 11 12.5 11Z"
        fill="#344054"
      />
      <path
        d="M12.8841 21.5C12.7147 21.0208 12.5979 20.5167 12.5406 19.9948H5C5.00214 19.6246 5.23063 18.5157 6.24815 17.4982C7.22653 16.5198 9.06632 15.5 12.5 15.5C12.8907 15.5 13.2608 15.5132 13.6114 15.5381C13.9491 15.026 14.3547 14.5628 14.8154 14.161C14.1156 14.0573 13.346 14 12.5 14C5 14 3.5 18.5 3.5 20C3.5 21.5 5 21.5 5 21.5H12.8841Z"
        fill="#344054"
      />
    </svg>
  );
};
